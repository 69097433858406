.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto, minmax(120px, auto));
  gap: 1rem;
}

.grid-container > span {
  border-bottom: 1px solid #aaa;
  border-right: 1px solid #aaa;
}
